import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import "../styles/gameeq.less"
import { Link } from "gatsby"
import { SaleForm } from "../components/form-biz"
import SEO from "../components/SEO"
import { Button } from "../components/ui"
const loadText = require("src/utils").loadText
const text = loadText("gameeq")
import { Solution } from "./products/bare-metal-cloud"
const url = loadText("url")
const TopBanner = () => {
  return (
    <div className="gameeq-topBanner">
      <div className="gameeq-topBanner-content full-bg">
        <Header logo="/logo-blue.png" theme="dark" />
        <div className="gameeq-topBanner-text">
          <div className="font-56 semi-bold">{text.topBanner.title}</div>
          <div className="font-24 semi-bold mt-24">
            {text.topBanner.subtitle}
          </div>
          <div
            className="font-20 mt-32 block-middle"
            style={{ maxWidth: "400px" }}
          >
            {text.topBanner.description}
          </div>
        </div>
      </div>
    </div>
  )
}
const B1 = () => (
  <div className="gameeq-b1">
    <div className="gameeq-b1-imgContainer">
      {["zenlayer", "equinix"].map(key => (
        <div>
          <img src={`/gameeq/${key}.png`} alt={key} />
        </div>
      ))}
    </div>
    <div
      className="font-36 semi-bold t-color mt-24 block-middle"
      style={{ maxWidth: "700px" }}
    >
      {text.b1.title}
    </div>
    <div className="font-20 mt-24 block-middle" style={{ maxWidth: "1016px" }}>
      {text.b1.description}
    </div>
  </div>
)
const B2 = () => (
  <div className="gameeq-b2">
    <div className="gameeq-b2-content full-bg">
      <div className="textContainer">
        <div className="font-36 semi-bold t-color">{text.b2.title}</div>
        <div className="font-20 mt-32">{text.b2.description}</div>
        <div className="mt-32 font-20">
          <Link to="/">{text.b2.linkName}</Link>
        </div>
      </div>
      <div className="imgContainer">
        <img src="/gameeq/b2-pic.png" alt={text.b2.title} />
      </div>
    </div>
  </div>
)
const Deployment = () => (
  <div className="gameeq-dep">
    <div className="font-36 semi-bold t-color">{text.deployment.title}</div>
    <div className="gameeq-dep-itemContainer mt-64">
      {["bmc", "sdn"].map(key => (
        <div className="gameeq-dep-itemWrap">
          <div
            className="gameeq-dep-item"
            style={{ backgroundImage: `url("/gameeq/${key}-bg.png")` }}
          >
            <div className="font-24 semi-bold t-color">
              {text.deployment[key].title}
            </div>
            <div className="mt-8 font-20">
              <Link to={url[key].link}>{text.deployment.learnMore}</Link>
            </div>
          </div>
          <div
            className="text font-16"
            style={{
              backgroundImage: `url("/gameeq/${key}-tip-bg.png")`,
              lineHeight: "28px",
            }}
          >
            {text.deployment[key].description}
          </div>
        </div>
      ))}
    </div>
  </div>
)
const Result = () => (
  <div className="gameeq-result">
    <div className="gameeq-result-content page-middle clearfix">
      <div className="imgContainer">
        <img src="/gameeq/result-pic.png" alt="" />
      </div>
      <div className="textContainer">
        <div className="semi-bold t-color font-36">{text.result.title}</div>
        <div className="font-20 mt-32" style={{ maxWidth: "520px" }}>
          {text.result.description}
        </div>
        <div className="mt-32 font-20">
          <Link to="/customer-story/gaming">{text.result.linkName}</Link>
        </div>
      </div>
    </div>
  </div>
)
const Custom = () => (
  <div className="gameeq-custom">
    <div className="gameeq-custom-content">
      <div className="imgContainer">
        <img src="/gameeq/custom-pic.png" alt={text.custom.title} />
      </div>
      <div className="textContainer">
        <div className="font-36 semi-bold">{text.custom.title}</div>
        <div
          dangerouslySetInnerHTML={{ __html: text.custom.description }}
          className="font-20 mt-16"
        />
        <div>
          <Button className="white" width={"95%"}>
            {text.custom.linkName}
          </Button>
        </div>
      </div>
    </div>
  </div>
)
const FormWrap = () => (
  <div style={{ background: "#f5f9ff" }}>
    <div className="gameeq-form">
      <div className="gameeq-form-content page-middle">
        <div className="gameeq-form-top text-center">
          <div className="font-36 semi-bold">{text.form.title}</div>
          <div className="font-20 mt-16">{text.form.description}</div>
        </div>
        <div className="gameeq-formWrap block-middle">
          <SaleForm />
        </div>
      </div>
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="gameeq">
      <SEO {...text.seo} />
      <TopBanner />
      <B1 />
      <FormWrap />
      <B2 />
      <Deployment />
      <Result />
      <Custom />
      <Solution {...text.partOfSolution} />
      <Footer />
    </div>
  )
}
